import {createApp,} from 'vue/dist/vue.esm-browser'

import Pagination from './ui.vue'


const initPagination = () => {
    const app = createApp({})
    app.component('Pagination', Pagination)

    app.mount('#pagination-custom')
}

export default initPagination