<template>
    <nav v-if="pageCount > 1" class="component-pagination" aria-label="Page navigation">
        <ul class="pagination" :class="[paginationClass, alignmentClass]">
            <li v-if="useFirstLast && pageCount > maxVisiblePages" class="page-item pagination-first-page"
                :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="changePage(1)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 15L7.5 10L12.5 5" stroke="#272728" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                </a>
            </li>
            <li v-if="usePrevNext" class="page-item pagination-prev-nav" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)" aria-label="Previous">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 15L7.5 10L12.5 5" stroke="#272728" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </a>
            </li>
            <template v-for="page in pagesToShow">
                <li v-if="page !== '<<' && page !== '>>' && page !== '...' || (page === '<<' && useFirstLast) || (page === '>>' && useFirstLast)"
                    :key="generateKey(page)" class="page-item pagination-page-nav"
                    :class="{ active: currentPage === page }">
                    <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li v-if="useDots && page === '...'" :key="generateKey(page)" class="page-item disabled">
                    <span class="page-link dotss">...</span>
                </li>
            </template>
            <li v-if="usePrevNext" class="page-item pagination-next-nav"
                :class="{ disabled: currentPage === pageCount }">
                <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)" aria-label="Next">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 15L12.5 10L7.5 5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </a>
            </li>
            <li v-if="useFirstLast && pageCount > maxVisiblePages" class="page-item pagination-last-page"
                :class="{ disabled: currentPage === pageCount }">
                <a class="page-link" href="#" @click.prevent="changePage(pageCount)">
                    <svg class="svg-icon svg">
                        <use :href="'/svg/icon.svg#icon-last-page'"></use>
                    </svg>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        parentCurrentPage: {
            type: [Number, String],
            required: true
        },
        totalPages: {
            type: [Number, String],
            required: true
        },
        // maxVisiblePages: {
        //     type: Number,
        //     default: 3
        // },
        useFirstLast: {
            type: Boolean,
            default: false
        },
        usePrevNext: {
            type: Boolean,
            default: true
        },
        useDots: {
            type: Boolean,
            default: true
        },
        align: {
            type: String,
            default: 'center'
        },
        size: {
            type: String,
            default: 'regular'
        },
        mediaQueries: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localCurrentPage: Number(this.parentCurrentPage)
        }
    },
    computed: {
        paginationClass() {
            return `pagination pagination-${this.size}`;
        },
        alignmentClass() {
            return `justify-content-${this.align}`;
        },
        pageCount() {
            return Number(this.totalPages)
        },
        currentPage() {
            return this.localCurrentPage
        },

        maxVisiblePages() {
            if (window.innerWidth < 768) {
                return 2
            }
            return 3
        },

        pagesToShow() {
            const pages = [];
            const isMaxTwo = this.maxVisiblePages == 2
            let startPage = Math.max(1, this.currentPage - Math.floor(this.maxVisiblePages / 2));
            let endPage = Math.min(this.pageCount, startPage + (!isMaxTwo ? this.maxVisiblePages - 1 : this.maxVisiblePages));
            let firstPage = this.pageCount - this.pageCount + 1;
            let adjustedMaxVisiblePages = this.maxVisiblePages;

            if (this.pageCount > this.maxVisiblePages + 1) {

                if (this.pageCount > adjustedMaxVisiblePages) {

                    if (this.currentPage <= this.maxVisiblePages) {
                        pages.push(firstPage);
                        if (startPage > 2) {
                            pages.push('...');
                        }
                    } else if (this.currentPage + adjustedMaxVisiblePages >= this.pageCount) {
                        pages.push(firstPage);
                        pages.push('...');

                    } else if (endPage < adjustedMaxVisiblePages) {

                        endPage = adjustedMaxVisiblePages;
                    } else if (endPage === this.pageCount) {
                        startPage = endPage - adjustedMaxVisiblePages + 1;
                    } else {
                        if (this.useDots) {
                            if (this.currentPage >= adjustedMaxVisiblePages) {
                                pages.push(firstPage);
                                if (isMaxTwo) {

                                    if (this.currentPage > 3) {
                                        pages.push('...');

                                    }
                                } else {
                                    console.log(' 1 ', 1);
                                    pages.push('...');
                                }


                            } else if (this.currentPage > adjustedMaxVisiblePages) {
                                if (pages.at(-1) != '...') {
                                    pages.push('...');
                                }
                            }
                        }
                    }
                } else if (this.maxVisiblePages >= this.pageCount) {
                    if (startPage > 1) {
                        pages.push(firstPage);
                        if (this.currentPage > 4) {
                            pages.push('...');
                        }
                    }
                }
            } else {
                startPage = 1
            }
            for (let i = startPage; i <= endPage; i++) {
                if (!pages.includes(i)) {
                    pages.push(i);
                }

            }
            if (this.useDots && this.pageCount > adjustedMaxVisiblePages && endPage < this.pageCount) {
                if (this.currentPage <= (this.pageCount - adjustedMaxVisiblePages + 1)) {

                    if (endPage + 1 != this.pageCount) {
                        pages.push('...');
                    }

                }
                pages.push(this.pageCount);
            }

            return pages;
        }
    },
    methods: {
        changePage(page) {
            if (page !== this.currentPage && page > 0 && page <= this.pageCount) {
                this.localCurrentPage = page


                const event = new CustomEvent('updatePage', {
                    detail: {
                        page: this.localCurrentPage
                    }
                });

                document.querySelector('.component-pagination .pagination').dispatchEvent(event);


            }
        },
        generateKey(page) {
            return typeof page === 'number' ? `page-${page}` : page;
        }
    }
}
</script>

<style lang="scss">

.pagination {

  &-regular {

    .page-item {
      .page-link {
        font-size: 14px;
        line-height: 20px;



      }
    }
  }


  .justify-content-center {
    justify-content: center;
  }

  .justify-content-start {
    justify-content: flex-start;
  }

  .justify-content-end {
    justify-content: flex-end;
  }
}
</style>
